import React from "react";

const paths = [
  {
    name: "instagram",
    url: "https://www.instagram.com/00vnex",
  },
  {
    name: "youtube",
    url: "https://www.youtube.com/@00vnex",
  },
  {
    name: "discord",
    url: "https://discord.com/users/785182341747441704",
  },
  {
    name: "beatstars",
    url: "https://www.beatstars.com/00vnex",
  },
  {
    name: "tiktok",
    url: "https://www.tiktok.com/@00vnex",
  },
];

function App() {
  if (window.location.hostname.startsWith("beats.")) {
    window.location.replace("https://www.beatstars.com/00vnex");
    return <></>;
  }

  const path = paths.find(
    (x) => x.name == window.location.pathname.substring(1)
  );

  if (path) {
    window.location.replace(path.url);
    return <></>;
  }

  return (
    <div className="App">
      <div className="Container">
        <a href="https://www.instagram.com/00vnex">instagram</a>
        <a href="https://www.tiktok.com/@00vnex">tiktok</a>
        <a href="https://www.youtube.com/@00vnex">youtube</a>
        {/* <a href="https://discord.com/users/785182341747441704">
          discord (vnex#2184)
        </a> */}
        <a href="https://www.beatstars.com/00vnex">beatstars</a>
        <a href="mailto:contact@vnex.party">contact</a>
      </div>
    </div>
  );
}

export default App;
